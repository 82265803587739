<template>
  <div class="grid">
    <loading
      :active="isLoadingModel"
      :can-cancel="true"
      color="#274461"
      :is-full-page="fullPage"
    ></loading>
    <Toast />
    <div class="col-12 sticky">
      <div id="custom_card" class="card">
        <div class="grid justify-content-between">
          <div class="col-12 md:col-6">
            <div
              class="flex flex-wrap align-items-center justify-content-center md:justify-content-start md:mb-0 mb-3"
            >
              <p style="color: #6b719b; font-size: 20px; font-weight: 800">
                 {{$t('settings')}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div id="custom_card" class="card" style="height: 600px">
        <div class="grid justify-content-between">
          <div class="col-12 md:col-3">
            <Menu :model="overlayMenuItems" style="width: 100% !important">
              <template #end>
                <button
                  :style="{
                    'margin-left': '0px',
                    'background-color': getBackgroundColor1(),
                  }"
                  @click="click1"
                  v-ripple
                  class="relative overflow-hidden w-full p-link align-items-center p-2 pl-3 text-color hover:surface-200 border-noround"
                >
                  <span class="inline-flex flex-column">
                    <span class="font-bold">{{$t('general')}}</span>
                  </span>
                </button>
                <button
                  :style="{
                    'margin-left': '2px',
                    'background-color': getBackgroundColor2(),
                  }"
                  @click="click2"
                  v-ripple
                  class="relative overflow-hidden w-full p-link align-items-center p-2 pl-3 text-color hover:surface-200 border-noround"
                >
                  <span class="inline-flex flex-column">
                    <span class="font-bold">{{$t('social_setting')}}</span>
                  </span>
                </button>
              </template>
            </Menu>
          </div>
          <div class="col-12 md:col-9" v-if="selectedTab == 'General'">
            <div class="p-fluid formgrid grid">
              <div class="field col-12 md:col-6" style="margin-top: 2%">
                <span class="p-float-label">
                  <InputText
                    id="ticket_title"
                    type="text"
                      onkeypress="return event.charCode >=65 && event.charCode <= 90 || event.charCode >=97 && charCode <= 122 ||
                      event.charCode == 32"
                    v-model="products.application_name"
                  />
                  <label for="ticket_title">{{$t('app_name')}}</label>
                </span>
              </div>
              <div class="field col-12 md:col-6" style="margin-top: 2%">
                <span class="p-float-label">
                  <InputText
                    id="ticket_title"
                    type="text"
                    v-model="products.email_id"
                  />
                  <label for="ticket_title">{{$t('email')}}</label>
                </span>
              </div>
              <div class="field col-12 md:col-6" style="margin-top: 2%">
                <!-- <vue-tel-input v-model="products.mobile_number" mode="international"></vue-tel-input> -->
                <span class="p-float-label">
                  <InputText
                    id="ticket_title"
                    type="text"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="10"
                    v-model="products.mobile_number"
                  />
                  <label for="ticket_title">{{$t('phone')}}</label>
                </span>
              </div>
              <div class="field col-12 md:col-6" style="margin-top: 2%">
                <span class="p-float-label">
                  <!-- <InputText
                    id="ticket_title"
                    type="text"
                    v-model="products.country_code"
                  /> -->
                  <!-- {{ country_data }} -->
                  <Dropdown
                    id="country_data"
                    v-model="country_selected"
                    :options="country_data"
                    :value="country_data.dial_code"
                       
                    optionLabel="dial_code"
                    :filter="true"
                  >
                  <template #value="slotProps">
                    <div class="flex align-items-center" v-if="slotProps.value">
                      <span :class="'mr-2 flag flag-' + slotProps.value.code.toLowerCase()" style="width:18px; height: 12px"/>
                      <div>{{slotProps.value.name}}</div>&nbsp;
                      <div>{{slotProps.value.dial_code}}</div>
                      
                    </div>
                   
                  </template>
                  <template #option="slotProps">
                    <div class="flex align-items-center">
                      <span :class="'mr-2 flag flag-' + slotProps.option.code.toLowerCase()" style="width:18px; height: 12px"/>
                      <div>{{slotProps.option.name}}</div>&nbsp;
                      <div>{{slotProps.option.dial_code}}</div>
                      
                    </div>
                  </template>
                </Dropdown>
                  <label for="ticket_title">{{$t('country_code')}}</label>
                </span>
              </div>
              <div class="field col-12 md:col-12" style="margin-top: 2%">
                <span class="p-float-label">
                  <Textarea
                    rows="3"
                    id="ticket_title"
                    type="text"
                    v-model="products.about_us"
                  />
                  <label for="ticket_title">{{$t('about')}}</label>
                </span>
              </div>
              <div class="field col-12 md:col-12" style="margin-top: 2%">
                <span class="p-float-label">
                  <Textarea
                    rows="3"
                    id="ticket_title"
                    type="text"
                    v-model="products.address"
                  />
                  <label for="ticket_title">{{$t('address')}}</label>
                </span>
              </div>
              <!-- =================================================================================== -->
              <div class="field col-12 md:col-2" style="margin-top: 2%">
                <FileUpload
                  class="p-button-primary"
                  mode="basic"
                  name="model[]"
                  style="
                    box-shadow: 0 2px 6px rgba(0, 176, 176, 0.549);
                    border-color: #00b0b0 !important;
                    background-color: #00b0b0 !important;
                  "
                  :auto="true"
                  :custom-upload="true"
                 
                  :choose-label="$t('logo')" 
                  @uploader="handleFileUpload1($event)"
                />
              </div>
              <div class="field col-12 md:col-2" style="margin-top: 1%">
                <img
                  :src="products.logo"
                  :alt="products.logo"
                  v-if="products.logo"
                  class="shadow-2"
                  width="70"
                />
              </div>

            <!-- =================================================================================== -->
              <div class="field col-12 md:col-2" style="margin-top: 2%">
                <FileUpload
                  class="p-button-primary"
                  mode="basic"
                  name="model[]"
                  style="
                    box-shadow: 0 2px 6px rgba(0, 176, 176, 0.549);
                    border-color: #00b0b0 !important;
                    background-color: #00b0b0 !important;
                  "
                  :auto="true"
                  :custom-upload="true"
              
                  :choose-label="$t('fevicon')" 
                  @uploader="handleFileUpload2($event)"
                />
              </div>
              <div class="field col-12 md:col-2" style="margin-top: 1%">
                <img
                  :src="products.fevicon"
                  :alt="products.fevicon"
                  v-if="products.fevicon"
                  class="shadow-2"
                  width="70"
                />
              </div>
            <!-- =================================================================================== -->
              <div class="field col-12 md:col-3" style="margin-top: 2%">
                <p>{{$t('verson')}}</p>
                <p>{{ products.current_version }}</p>
              </div>
              <div class="field col-12 md:col-2" style="margin-top: 2%;margin-left: 200px; ">
                <Button
                  class="p-button-primary btn_light_blue"
                  @click="save_client()"
                  :label="$t('save') "
                />
              </div>
            </div>
          </div>
          <div class="col-12 md:col-9" v-if="selectedTab == 'Social Settings'">
            <div class="p-fluid formgrid grid">
              <div class="field col-12 md:col-6" style="margin-top: 2%">
                <div class="p-inputgroup">
                  <span class="p-inputgroup-addon">
                    <i class="pi pi-facebook"></i>
                  </span>
                  <InputText
                  
                    :placeholder="$t('facebook_url')" 
                    v-model="products.facebook_url"
                  />
                </div>
              </div>
              <div class="field col-12 md:col-6" style="margin-top: 2%">
                <div class="p-inputgroup">
                  <span class="p-inputgroup-addon">
                    <i class="pi pi-twitter"></i>
                  </span>
                  <InputText
                  
                    :placeholder="$t('twitter_url')" 
                    v-model="products.twitter_url"
                  />
                </div>
              </div>
              <div class="field col-12 md:col-6" style="margin-top: 2%">
                <div class="p-inputgroup">
                  <span class="p-inputgroup-addon">
                    <i class="pi pi-linkedin"></i>
                  </span>
                  <InputText
                  
                    :placeholder="$t('linkedin_url')" 
                    v-model="products.linkedin_url"
                  />
                </div>
              </div>
              <div class="field col-12 md:col-6" style="margin-top: 2%"></div>
              <div class="field col-12 md:col-2" style="margin-top: 2%">
                <Button
                  class="p-button-primary btn_light_blue"
                  @click="save_client()"
                 
                   :label="$t('save') "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import apis from "@/apis";
import axios from "axios";
import CountryCodeService from "../service/CountryCodeService";
export default {
  name: "EticketSettings",
  data() {
    return {
      products: [],
      selectedTab: "General",
      isLoadingModel: false,
      fullPage: false,
      overlayMenuItems: [],
      CountryCodeService:null,
      CodeService:null,
      country_data:[],
      country_selected:"",
      countryid:""
    };
  },
  async mounted() {
    
     this.CountryCodeService = new CountryCodeService();
    await this.CountryCodeService.GetCountryCode().then((data) => {
			this.country_data = data;
		 });
     await this.get_client();
  },
  computed: {
    currentLocale() {
      
      return this.$i18n.locale;
    },
   

  },
  methods: {
    async handleFileUpload1(event)
        {
            var fileUp  =   event.files[0];
            var file    =   fileUp;
            this.file   =   file;
            var prfeix= await this.date_time_prefix();
            var filename = prfeix+"_"+file.name;
            this.file_data = 
            {
                filePath:filename,
                contentType: file.type,
            };

            var promise = apis.upload_to_AWS(this.file_data);
                promise.then((response) => {
        
                axios
                .put(response.data.data, file, {
                    headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": file.type,
                    },
                })
                .then((response) => {
                  this.products.logo="https://eticket-docs.s3.ap-south-1.amazonaws.com/" +filename;
                 
                   
                });
            });

        },
        async handleFileUpload2(event)
        {
            var fileUp  =   event.files[0];
            var file    =   fileUp;
            this.file   =   file;
            var prfeix= await this.date_time_prefix();
            var filename = prfeix+"_"+file.name;
            this.file_data = 
            {
                filePath:filename,
                contentType: file.type,
            };

            var promise = apis.upload_to_AWS(this.file_data);
                promise.then((response) => {
        
                axios
                .put(response.data.data, file, {
                    headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": file.type,
                    },
                })
                .then((response) => {
                    this.products.fevicon="https://eticket-docs.s3.ap-south-1.amazonaws.com/" +filename;
                
                   
                });
            });

        },
    async date_time_prefix() 
        {
            // Get the current date and time
            const currentDateTime = new Date();

            // Format the date as YYYY_MM_DD
            const formattedDate = currentDateTime.toISOString().split('T')[0].replace(/-/g, '_');

            // Format the time as HH_mm_ss
            const formattedTime = currentDateTime.toTimeString().split(' ')[0].replace(/:/g, '_');

            // Construct the final string
            const resultString = `${formattedDate}_${formattedTime}`;

            return resultString;
        },
    save_client() {
      if (this.products.application_name == "") {
          this.$toast.add({
            severity: "error",
            summary: "error",
            detail: this.$t('pleaseEnterApplicationName'),
            life: 3000,
          });
          return false;
        }
        if (this.products.email_id == '') {
                this.$toast.add({
                    severity: "error",
                    summary: "error",
                    //detail: "Please Enter Email ID",
                    detail:this.$t("email_empty_error"),
                    life: 3000,
                });
                return false;
            }


            const emailPattern = /^[a-z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,}$/;
            if (!emailPattern.test(this.products.email_id)) {
                this.$toast.add({
                    severity: "error",
                    summary: "Error",
                   // detail: "Invalid Email ID",
                    detail:this.$t("invalid_email"),
                    life: 3000,
                });
                return false;
            }
            if (this.products.mobile_number.length > 10 || this.products.mobile_number.length < 10) {
                this.$toast.add({
                    severity: "error",
                    summary: "error",
                    //detail: "Please Enter Valid Mobile Number",
                    detail:this.$t("mobile_invalid_length_error"),
                    life: 3000,
                });
                return false;
            }

            if (this.products.mobile_number.length !== 10) {
                // Condition: Mobile number length is not 10
                this.$toast.add({
                    severity: "error",
                    summary: "Error",
                   // detail: "Mobile number must be exactly 10 digits.",
                    detail:this.$t("mob_tendigit"), 
                    life: 3000,
                }); 
                return false;
            }

            if (!/^\d+$/.test(this.products.mobile_number)) {
                // Condition: Mobile number contains non-numeric characters
                this.$toast.add({
                    severity: "error",
                    summary: "Error",
                   // detail: "Mobile number must contain only digits.",
                    detail:this.$t("mobile_numeric_error"),
                    life: 3000,
                });
                return false;
            }

            if (/^0+$/.test(this.products.mobile_number) || /^0/.test(this.products.mobile_number)) {
                // Condition: Mobile number contains all zeros or starts with zero
                this.$toast.add({
                    severity: "error",
                    summary: "Error",
                   // detail: "Mobile number cannot start with zero or contain all zeros.",
                    detail:this.$t("mobile_zero_error"),
                    life: 3000,
                });
                return false;
            }
            if (!this.country_selected) {
              this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: this.$t("select_country_code_error"),
                  life: 3000,
              });
              return false;
            }













      this.countryid = "";
      this.countryid = this.country_selected.dial_code?this.country_selected.dial_code.toString():"";

      var data = {
        client_id: localStorage.getItem('client_id'),
        name: this.products.name,
        application_name: this.products.application_name,
        email_id: this.products.email_id,
        mobile_number: this.products.mobile_number,
        country_code: this.countryid,
        about_us: this.products.about_us,
        address: this.products.address,
        logo: this.products.logo,
        fevicon: this.products.fevicon,
        current_version: this.products.current_version,
        status: this.products.status,
        facebook_url: this.products.facebook_url,
        linkedin_url: this.products.linkedin_url,
        twitter_url: this.products.twitter_url,
        user_id: localStorage.getItem("id"),
        lang:this.$i18n.locale
      };
      this.isLoadingModel=true;
      var promise = apis.updateClientProfile(data, this.id);
      promise.then((response) => {
        this.isLoadingModel=false;
        // this.$swal({title:response.data.message , confirmButtonText: this.$t('ok')});
            this.$toast.add({
            severity: "success",
            summary: this.$t("success"),
            detail: response.data.message,
            life: 3000,
          });
        this.get_client();
      });
    },
    async get_client() {
      this.products = [];
      var data = {
        client_id: localStorage.getItem('client_id'),
        user_id: localStorage.getItem("id"),
      };
      var promise = apis.getClientList(data, this.id);
      promise.then((response) => {
        this.products = response.data.data[0];
        console.log("🚀 ~ promise.then ~ this.products:", this.products);
        if (this.products.country_code) 
        {
            for (let index = 0; index < this.country_data.length; index++) 
            {
                if (this.country_data[index]['dial_code']==this.products.country_code) 
                {
                  this.country_selected=this.country_data[index];
                }
              
            }
        }
      });
    },
    getBackgroundColor1() {
      if (this.selectedTab === "General") {
        return "#00b0b0 !important";
      } else {
        return "#FFFFFF !important";
      }
    },
    getBackgroundColor2() {
      if (this.selectedTab === "Social Settings") {
        return "#00b0b0 !important";
      } else {
        return "#FFFFFF !important";
      }
    },
    click1() {
      this.selectedTab = "General";
    },
    click2() {
      this.selectedTab = "Social Settings";
    },
  },
};
</script>
<style scoped>
</style>